<template>
	<el-icon v-if="icon=='setting'"><Setting/></el-icon>
	<el-icon v-if="icon=='expand'"><Expand/></el-icon>
	<el-icon v-if="icon=='fold'"><Fold/></el-icon>
	<el-icon v-if="icon=='Edit'"><Edit/></el-icon>
	<el-icon v-if="icon=='Delete'"><Delete/></el-icon>
	<el-icon v-if="icon=='Search'"><Search/></el-icon>
	<el-icon v-if="icon=='Plus'"><Plus/></el-icon>
	<el-icon v-if="icon=='User'"><User/></el-icon>
	<el-icon v-if="icon=='Lock'"><Lock/></el-icon>
	<el-icon v-if="icon=='Unlock'"><Unlock/></el-icon>
	<el-icon v-if="icon=='DocumentCopy'"><DocumentCopy /></el-icon>
	<el-icon v-if="icon=='SetUp'"><SetUp /></el-icon>
	<el-icon v-if="icon=='MilkTea'"><MilkTea /></el-icon>
	<el-icon v-if="icon=='RefreshLeft'"><RefreshLeft /></el-icon>
	<el-icon v-if="icon=='View'"><View /></el-icon>
	<el-icon v-if="icon=='Timer'"><Timer /></el-icon>
	<el-icon v-if="icon=='Picture'"><Picture /></el-icon>
	<el-icon v-if="icon=='AddLocation'"><AddLocation /></el-icon>
	<el-icon v-if="icon=='Wallet'"><Wallet /></el-icon>
	<el-icon v-if="icon=='Menu'"><Menu /></el-icon>
	<el-icon v-if="icon=='Share'"><Share /></el-icon>
	<el-icon v-if="icon=='ArrowDown'"><ArrowDown /></el-icon>
	<el-icon v-if="icon=='MoreFilled'"><MoreFilled /></el-icon>
	<el-icon v-if="icon=='Grid'"><Grid /></el-icon>
	<el-icon v-if="icon=='Download'"><Download /></el-icon>
	<el-icon v-if="icon=='ShoppingCartFull'"><ShoppingCartFull /></el-icon>
	<el-icon v-if="icon=='Link'"><Link /></el-icon>
	<el-icon v-if="icon=='CopyDocument'"><CopyDocument /></el-icon>
	<el-icon v-if="icon=='Upload'" :size="20"><Upload /></el-icon>
	<el-icon v-if="icon=='VideoPlay'" :size="32"><VideoPlay /></el-icon>
</template>

<script>
	import { Link,CopyDocument,Download,Setting,Grid,ShoppingCartFull,ArrowDown,MoreFilled,Share,Menu,Wallet,Upload,AddLocation,Picture,Timer,VideoPlay,Expand,Fold,Edit,Delete,Search,Plus,Lock,Unlock,DocumentCopy,SetUp,MilkTea,RefreshLeft,User,View } from '@element-plus/icons-vue'
	export default{
		props:['icon'],
		components:{Link,CopyDocument,Download,Setting,Grid,ShoppingCartFull,ArrowDown,MoreFilled,Share,Menu,Wallet,AddLocation,Upload,Picture,Timer,VideoPlay,Expand,Fold,Edit,Delete,Search,Plus,Lock,Unlock,DocumentCopy,SetUp,MilkTea,RefreshLeft,User,View},
		data(){
			return{
				
			}
		}
	}
</script>

<style scoped>
</style>
